type Theme = {
  text: string;
  titleColor: string;
};

const theme: Theme = {
  text: '#43a047',
  titleColor: '#7cb342',
};

export default theme;

