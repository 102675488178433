import React from "react";

function FadeInSection(props: any) {
  const [isVisible, setVisible] = React.useState(true);
  const domRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => setVisible(entry.isIntersecting));
    });
    domRef.current && observer.observe(domRef.current);
    const foo = domRef.current;
    return () => {
      if (foo) {
        observer.unobserve(foo);
      }
    };
  }, []);

  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

export default FadeInSection;
