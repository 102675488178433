import React from "react";
import styled from "@emotion/styled";
import { DateTime } from "luxon";
import parse from "html-react-parser";
import theme from "../theme";

const Text = styled.div`
  font-size: 1em;
  line-height: 1.2em;
  font-style: italic;
`;

const Title = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  font-style: italic;
  
  color: ${theme.titleColor};
`;

const Description = styled.div`
  margin-top: 0.5em;
  font-size: 1em;
  border-left: 3px solid ${theme.titleColor};
  padding: 0.5em;
  text-align: justify;
`;

const Container = styled.div`
  padding: 0.5em;
  margin-bottom: 1em;
  text-align: left;
  break-inside: avoid;
`;

const Project = (props) => {
  const start = DateTime.fromISO(props.start);
  const end = props.end ? DateTime.fromISO(props.end).plus({ days: 1 }) : DateTime.now();
  const diff = end.diff(start, ["years", "months", "days", "hours"]).toObject();

  let diffStr = `${diff.months} months`;
  if (diff.years > 0 && diff.months > 0) {
    diffStr = `${diff.years} years, ${diff.months} months`;
  } else if (diff.years > 0) {
    diffStr = `${diff.years} years`;
  }

  return (
    <Container>
      <Title>{props.title}</Title>
      <Text>
        When: {props.start} - {props.end || 'ongoing'} ({diffStr})
      </Text>
      {props.customer && (
        <Text>
          Customer: <strong>{props.customer}</strong>
        </Text>
      )}
      <Text>
        Employer: <strong>{props.employer}</strong>
      </Text>
      <Description>
        {parse(props.description)}
        {props.impact && (
          <div style={{ paddingTop: 20 }}>
            <strong>Impact:</strong> {parse(props.impact)}
          </div>
        )}
        {props.tech && (
          <div style={{ paddingTop: 20 }}>
            <strong>Technologies:</strong> {props.tech.join(", ")}
          </div>
        )}
      </Description>
    </Container>
  );
};

export default Project;
