import React from "react";
import styled from "@emotion/styled";
import { useNavigate, Outlet } from "react-router-dom";
import theme from "./theme";

const Button = styled.button`
  background-color: #000;
  min-height: 30px;
  width: 120px;
  color: white;
  font-family: Roboto;
  font-weight: bold;
  font-size: 1em;
  color: ${theme.text};
  text-transform: uppercase;
  border: 1px solid ${theme.text};
  border-radius: 4px;

  ${(props: { active: boolean }) =>
    props.active &&
    `
    background-color: ${theme.text};
    color: white;
  `}

  &:hover {
    opacity: 0.8;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
`;

const PageContainer = styled.div`
  padding: 10px;
  text-align: center;

  height: 100%;
  color: ${theme.text};
`;

const MenubarContainer = styled.div`
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 600px) {
    justify-content: center;
  }
`;

function Page() {
  const navigate = useNavigate();

  const path = window.location.pathname;

  return (
    <PageContainer>
      <MenubarContainer>
        <Button active={path === "/"} onClick={() => navigate("/")}>
          Front page
        </Button>
        <Button active={path === "/resume"} onClick={() => navigate("/resume")}>
          Resume
        </Button>
      </MenubarContainer>
      <Outlet />
    </PageContainer>
  );
}

export default Page;
