import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
//import shuttle from "./images/shuttle.png";
import FadeInSection from "./FaceInSection";
import facePic from "./images/face.png";
import data from "./data.json";
import theme from "./theme";

// const Shuttle = styled.img`
//   position: absolute;
//   right: 100px;
//   width: 12vw;
//   padding-left: 20px;
//   opacity: 0.8;

//   @media screen and (max-width: 600px) {
//     visibility: hidden;
//   }
// `;

const StyledText = styled.p`
  text-align: justify;
  text-justify: inter-word;
  padding-bottom: 40px;
  width: 60vw;

  @media screen and (max-width: 600px) {
    width: 90vw;
  }

  font-size: 1.5em;
`;

const TextBlockContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Face = styled.img`
  width: 15vw;
  border-radius: 50%;
  float: left;
  margin-right: 20px;
  margin-bottom: 20px;
  opacity: 0.8;
`;

const StyledLink = styled(Link)`
  color: ${theme.titleColor};
`;

const Title = styled.div`
  font-size: 2em;
  font-weight: bold;
  color: ${theme.titleColor};
`;

const Subtitle = styled.div`
  color: ${theme.titleColor};
  font-style: italic;
`;

const Main = () => {
  return (
    <div>
      <TextBlockContainer>
        <StyledText>
          <FadeInSection>
            <p style={{ textAlign: "center", marginBottom: 50 }}>
              <Title>Qmoduuli Oy</Title>
              <Subtitle>
                Your trusted companion even on the most challenging journeys
              </Subtitle>
            </p>
          </FadeInSection>

          <FadeInSection>
            <p>
              <Face src={facePic} alt="Mikko Väänänen" />
              I'm Mikko Väänänen, a seasoned freelance developer specializing in
              web and mobile applications, based in Finland. With over two
              decades of experience in the software industry, my expertise spans
              a broad range of technologies, including React, React Native,
              NodeJS, Angular, Java, Spring Boot, TypeScript, PostgreSQL, Docker, and AWS.
            </p>
          </FadeInSection>

          <FadeInSection>
            <p>
              My approach as a full-stack developer is grounded in effective
              communication, a pragmatic mindset, and a strong focus on value. I
              pride myself on delivering top-quality software solutions that
              meet customer needs, backed by a proven track record in various
              roles across the software sector.
            </p>
          </FadeInSection>

          <FadeInSection>
            <p>
              As a team player committed to excellence, I thrive in environments
              that prioritize high standards, open communication, and continuous
              learning and improvement. My passion for acquiring new skills and
              enhancing my knowledge is ceaseless.
            </p>
          </FadeInSection>

          <FadeInSection>
            <p>
              Based in Pirkkala, near Tampere, I am well-versed in remote work,
              having embraced location-independent roles for several years. I
              firmly believe in building trust, fostering great team spirit, and
              ensuring excellent cooperation, even in remote team settings.
            </p>
          </FadeInSection>

          <FadeInSection>
            <p>
              For a detailed view of my professional journey and skills, please
              take a moment to review my{" "}
              <StyledLink to="/resume">resume</StyledLink>. If my profile aligns
              with your needs, I would be delighted to discuss potential
              collaborations!
            </p>
          </FadeInSection>

          <FadeInSection>
            <p>
              With best regards,
              <br />
              Mikko Väänänen
              <br />
              <br />
              {data.contact.email}
              <br />
              <StyledLink to={data.contact.linkedInUrl}>
                LinkedIn profile
              </StyledLink>
            </p>
          </FadeInSection>
        </StyledText>
      </TextBlockContainer>
    </div>
  );
};

export default Main;
