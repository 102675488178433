import styled from "@emotion/styled";
import parse from "html-react-parser";
import { Link } from "react-router-dom";
import React from "react";
import facePic from "../images/face.png";
import data from "../data.json";
import TopList from "./TopList";
import Project from "./Project";
import Education from "./Education";
import FadeInSection from "../FaceInSection";
import theme from "../theme";

const Left = styled.div`
  flex: 2;
  text-align: center;
  padding: 40px 20px 20px 20px;
`;

const Right = styled.div`
  flex: 8;
  text-align: center;
  padding: 20px 40px;

  @media screen and (max-width: 600px) {
    padding: 20px 20px;
  }
`;

const Face = styled.img`
  width: 220px;
  border: 2px solid #fff;
  border-radius: 50%;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  flex-direction: row;

  @media screen and (max-width: 600px) {
    flex-direction: column;
  }

  print-color-adjust: exact;
`;

const ContactInfo = styled.div`
  color: ${theme.titleColor};
  text-align: left;
  font-size: 1.2em;
`;

const About = styled.div`
  text-align: justify;
  font-size: 1.2em;
`;

const Section = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const Quote = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  font-size: 1.2em;
  font-style: italic;
  text-align: justify;
  break-inside: avoid;
`;

const QuoteAuthor = styled.div`
  color: ${theme.titleColor};
  font-weight: bold;
`;

const StyledLink = styled(Link)`
  color: ${theme.titleColor};
`;

const Menu = () => {
  return (
    <Container>
      <Left>
        <Face src={facePic} alt="Face" />

        <FadeInSection>
          <ContactInfo>
            <div style={{ paddingTop: 20 }}>{data.contact.email}</div>
            <div style={{ paddingTop: 10 }}>{data.contact.phone}</div>
            {data.contact.linkedInUrl && (
              <div style={{ paddingTop: 10, paddingBottom: 20 }}>
                <StyledLink to={data.contact.linkedInUrl}>
                  LinkedIn profile
                </StyledLink>
              </div>
            )}
          </ContactInfo>
        </FadeInSection>

        <FadeInSection>
          <TopList title="Top Tech Skills" items={data.topTech} />
        </FadeInSection>

        <FadeInSection>
          <TopList title="Top Traits" items={data.topTraits} />
        </FadeInSection>
      </Left>
      <Right>
        <Section>
          <FadeInSection>
            <h1>About me</h1>
          </FadeInSection>

          <FadeInSection>
            <About>{parse(data.about)}</About>
          </FadeInSection>

          <FadeInSection>
            <Quote>
              "He is easy to work with, talented in his field and very
              reliable."
              <br />
              <QuoteAuthor>--- a product manager at HSL</QuoteAuthor>
            </Quote>
          </FadeInSection>

          <FadeInSection>
            <Quote>
              "Pitkän linjan kokemus näkyy rauhallisena ja varmana otteena
              työssään tehokkuudesta tinkimättä."
              <br />
              <QuoteAuthor>--- a service manager at Elenia</QuoteAuthor>
            </Quote>
          </FadeInSection>
        </Section>

        <Section>
          <FadeInSection>
            <h1>Work and project experience</h1>
          </FadeInSection>
          {data.projects
            .filter((project) => project.enabled)
            .map((project) => (
              <FadeInSection>
                <Project
                  key={project.id}
                  tech={project.tech}
                  title={project.title}
                  start={project.start}
                  end={project.end}
                  customer={project.customer}
                  employer={project.employer}
                  description={project.description}
                  impact={project.impact}
                />
              </FadeInSection>
            ))}
        </Section>

        <Section style={{ pageBreakBefore: "always" }}>
          <FadeInSection>
            <h1>Education</h1>
          </FadeInSection>
          {data.education.map((education) => (
            <FadeInSection>
              <Education
                key={education.degree}
                degree={education.degree}
                school={education.school}
                when={education.when}
              />
            </FadeInSection>
          ))}
        </Section>

        <Section>
          <FadeInSection>
            <h1>Recommendations</h1>
          </FadeInSection>

          <FadeInSection>
            <Quote>
              "Mikon kanssa on helppo työskennellä. Hän on omistautunut työlleen
              ja on valmis venymään tarvittaessa, koska hänellä on aina myös
              asiakkaan loppuasiakas kirkkaana mielessä. Ehdottomana plussana
              karttunut ymmärrys energia-alasta ja siihen liittyvistä teknisistä
              yksityiskohdista ja markkinasta. Pitkän linjan kokemus näkyy
              rauhallisena ja varmana otteena työssään tehokkuudesta
              tinkimättä."
              <br />
              <QuoteAuthor>
                --- Tiina Salmi, Service Manager at Elenia (customer, 2024)
              </QuoteAuthor>
            </Quote>
          </FadeInSection>

          <FadeInSection>
            <Quote>
              "Mikko is one of the most experienced developers in HSL mobile
              app-team. He easily understands the business logic and puts that
              into code. He is easy to work with, talented in his field and very
              reliable. He finds solutions even under pressure and with very
              little sleep – like after a night when HSL converted into new
              zones. I highly recommed Mikko as a senior level developer."
              <br />
              <QuoteAuthor>
                --- a Product Manager at HSL (customer, 2019)
              </QuoteAuthor>
            </Quote>
          </FadeInSection>

          <FadeInSection>
            <Quote>
              "In a desperate need for talented developer that documents both
              his code and the findings, people (non-devs) recommended Mikko to
              me. I decided to reach out to Mikko, and he jumped in instantly.
              Fast forward to results.. I got easy to comprehend documentation,
              clean code and debriefing. I am very pleased and can easily
              recommend Mikko for senior level dev tasks."
              <br />
              <QuoteAuthor>
                --- a CEO at StoneCrew Oy (customer, 2017)
              </QuoteAuthor>
            </Quote>
          </FadeInSection>

          <FadeInSection>
            <Quote>
              "It was an honor to work with Mikko at eTasku Solutions Oy. He has
              the right attitude what comes to web development. He's eager to
              learn new technologies and loves to keep himself up to date with
              latest tricks."
              <br />
              <QuoteAuthor>
                --- a CTO at eTasku Solutions Oy (employer, 2017)
              </QuoteAuthor>
            </Quote>
          </FadeInSection>
        </Section>
      </Right>
    </Container>
  );
};

export default Menu;
