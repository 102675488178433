import React from "react";
import styled from "@emotion/styled";

const Title = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  font-style: italic;
  color: green;
`;

const TechItem = styled.li`
  padding-bottom: 0.5em;
  font-size: 1em;
  font-style: italic;
  color: #green;
`;

const Container = styled.div`
  padding: 0.5em;
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: left;
`;

const TopTech = (props) => {
  return (
    <Container>
      <Title>{props.title}</Title>
      <ul>
        {props.items.map((item) => (
          <TechItem key={item}>{item}</TechItem>
        ))}
      </ul>
    </Container>
  );
};

export default TopTech;
