import React from "react";
import styled from "@emotion/styled";
import theme from "../theme";

const Text = styled.div`
  font-size: 1em;
  line-height: 1.5em;
  font-style: italic;
`;

const School = styled.div`
  line-height: 1.5em;
  font-weight: bold;
  font-style: italic;
`;

const Degree = styled.div`
  font-size: 1.5em;
  font-weight: bold;
  font-style: italic;
  color: ${theme.titleColor};
`;

const Container = styled.div`
  padding: 0.5em;
  margin-bottom: 1em;
  text-align: left;
  break-inside: avoid;
`;

const Education = (props) => {
  return (
    <Container>
      <Degree>{props.degree}</Degree>
      <School>{props.school}</School>
      <Text>{props.when}</Text>
    </Container>
  );
};

export default Education;
