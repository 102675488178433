import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import Menubar from "./Menubar";
import Resume from "./Resume";
import Main from "./Main";
//import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
//import { createTheme, ThemeProvider } from "@mui/material/styles";
//import { red } from "@mui/material/colors";
//import theme from "./theme";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

// const appTheme = createTheme({
//   palette: {
//     primary: {
//       main: theme.text,
//     },
//     secondary: red,
//   },
// });

const router = createBrowserRouter([
  {
    path: "/",
    element: <Menubar />,
    errorElement: <div>Not implemented yet!</div>,
    children: [
      {
        path: "/",
        element: <Main />,
      },
      {
        path: "/resume",
        element: <Resume />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
